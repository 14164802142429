import { Type } from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsDate,
  IsNumber,
  IsOptional,
  IsString,
  ValidateIf,
  ValidateNested,
} from 'class-validator';

import { OrderItemBaseObject } from '../objects/order-item-base.object';

/**
 * Create an order.
 */
export class CreateOrderRequest {
  /**
   * Restaurant id related to the order.
   *
   * @type {number}
   * @memberof CreateOrderRequest
   */
  @IsNumber()
  restaurantId: number;

  /**
   * Campaign id related to the order.
   *
   * @type {number}
   * @memberof CreateOrderRequest
   */
  @IsNumber()
  campaignId: number;

  /**
   * Items in the order. Could be nested.
   *
   * @type {OrderItemBaseObject[]}
   * @memberof CreateOrderRequest
   */
  @Type(() => OrderItemBaseObject)
  @ValidateNested()
  @IsArray()
  items: OrderItemBaseObject[];

  /**
   * Date of retrieval for the order, optionnal if the order is created in MOP (predict).
   *
   * @type {Date}
   * @memberof CreateOrderRequest
   */
  @ValidateIf((request) => request.isPredictOrder === false)
  @Type(() => Date)
  @IsDate()
  retrievalDate: Date;

  /**
   * firstname of the customer related to the order.
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  @IsString()
  @IsOptional()
  firstName?: string;
  /**
   * firstname of the customer related to the order.
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  @IsString()
  @IsOptional()
  lastName?: string;
  /**
   * firstname of the customer related to the order.
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  @IsString()
  @IsOptional()
  email?: string;
  /**
   * firstname of the customer related to the order.
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  @IsString()
  @IsOptional()
  phoneNumber?: string;
  /**
   * Is the order is created in MOP or not
   *
   * @type {boolean}
   * @memberof CreateOrderRequest
   */
  @IsBoolean()
  isPredictOrder: boolean;
  /**
   * Predict sessionId if the order is created in MOP
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  @IsOptional()
  @IsString()
  sessionId?: string;
  /**
   * Is the user on his way to pick up is order or not
   *
   * @type {boolean}
   * @memberof CreateOrderRequest
   */
  @IsOptional()
  @IsBoolean()
  isOnMyWay?: boolean;
  /**
   * the estimated time of arrival (in second) of the user on MOP
   *
   * @type {number}
   * @memberof OrderObject
   */
  @IsOptional()
  @IsNumber()
  estimatedTimeOfArrival?: number;
  /**
   * Next interval check (in second) for user arrival on MOP
   *
   * @type {number}
   * @memberof OrderObject
   */
  @IsOptional()
  @IsNumber()
  nextETACheck?: number;
  /**
   * user's pickup mode
   *
   * @type {string}
   * @memberof OrderObject
   */
  @IsOptional()
  @IsString()
  pickupMode?: string;
}
