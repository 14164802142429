import { Type } from 'class-transformer';

import { PaginatedResponse } from '../../common/paginated-response';
import { GuestObject } from '../../guests/objects/guest.object';
import { OrderObject } from '../../orders/objects/order.object';
import { UserObject } from '../../users/objects/user.object';

class OrderInformation {
  order: OrderObject;
  account: UserObject | GuestObject;
}

export class PaginatedRestaurantOrdersObject implements PaginatedResponse<OrderInformation> {
  /**
   * list of elements
   */
  @Type(() => OrderInformation)
  items: OrderInformation[];

  /**
   * number of total elements
   */
  count: number;

  /**
   * number to display
   */
  limit: number;

  /**
   * number to skip
   */
  skip: number;
}
