import { Type } from 'class-transformer';
import { IsNumber, IsPositive, IsOptional, IsObject, IsString } from 'class-validator';

class FiltersData {
  @IsString()
  @IsOptional()
  name?: string;
}

export class GetProductsByFilterObject {
  @IsNumber()
  @IsPositive()
  campaignId: number;

  @IsNumber()
  @IsPositive()
  restaurantId: number;

  @IsObject()
  @Type(() => FiltersData)
  filters: FiltersData;
}
