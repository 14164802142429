/**
 * Provider of a payment.
 */
export enum PaymentProvider {
  NEPTING = 'NEPTING',
  STRIPE = 'STRIPE',
  FAKE = 'FAKE',
}

/**
 * State of a payment.
 */
export enum PaymentState {
  /**
   *  Payment created, maybe waiting for confirmation from the customer.
   */
  CREATED = 'CREATED',
  /**
   * Payment confirmed, waiting to be captured after sending the related order.
   */
  CONFIRMED = 'CONFIRMED',
  /**
   * Payment paid.
   */
  PAID = 'PAID',
  /**
   * Payment refund. When an order failed to be injected.
   */
  REFUNDED = 'REFUNDED',
  /**
   * Payment cancelled.
   */
  CANCELLED = 'CANCELLED',
}

/**
 * Represent an atomic payment for an order.
 */
export class PaymentObject {
  /**
   * Id of the payment.
   *
   * @type {number}
   * @memberof PaymentObject
   */
  id: number;

  /**
   * Order id related to the payment.
   *
   * @type {number}
   * @memberof PaymentObject
   */
  orderId: number;

  /**
   * Amount of the payment.
   *
   * @type {number}
   * @memberof PaymentObject
   */
  amount: number;

  /**
   * Provider of the payment.
   *
   * @type {PaymentProvider}
   * @memberof PaymentObject
   */
  provider: PaymentProvider;

  /**
   * State of the payment.
   *
   * @type {PaymentState}
   * @memberof PaymentObject
   */
  state: PaymentState;
}
