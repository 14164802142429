import { Type } from 'class-transformer';
import { IsOptional } from 'class-validator';

/**
 * Describe a restaurant of a campany.
 */
export class PredictStatusObject {
  /**
   * Status code
   */
  @Type(() => Number)
  @IsOptional()
  StatusCode: number;

  /**
   * Status message
   */
  @Type(() => String)
  @IsOptional()
  StatusMessage: string;

  /**
   * Id of the session
   */
  @Type(() => String)
  @IsOptional()
  SessionId: string;

  /**
   * Estimated time of arrival of the user
   */
  @Type(() => Number)
  @IsOptional()
  ETA: number;

  /**
   * Next check of the user's location
   */
  @Type(() => Number)
  @IsOptional()
  NextETACheck: number;

  /**
   * Status of the transaction
   */
  @Type(() => String)
  @IsOptional()
  Status: string;
}
