import { IsEnum, IsOptional, IsString } from 'class-validator';

/**
 * Type of external authentication
 */
export enum AuthType {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
}

/**
 * Request data used to authenticate a user.
 */
export class AuthenticateExternalUserRequest {
  @IsEnum(AuthType)
  authType: AuthType;

  @IsString()
  accessToken: string;

  @IsString()
  @IsOptional()
  language?: string | null;
}
