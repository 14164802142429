import { ProductPartType, ProductType } from '@acrelec-cloud/apico-shared';

import { ContentsMocksDatabase } from '../contents.mocks';

export const contentsDatabase: ContentsMocksDatabase = {
  navigations: [
    {
      id: 12,
      name: 'Online',
      displayName: 'Online',
      campaignId: 1,
      sortOrder: 2,
      parentId: null,
      childrenCount: 1,
      productsCount: 1,
    },
    {
      id: 13,
      name: 'Lunch Meal',
      displayName: 'Lunch Meal',
      campaignId: 1,
      sortOrder: 1,
      parentId: 12,
      childrenCount: 1,
      productsCount: 1,
    },
    {
      id: 14,
      name: 'Burgers',
      displayName: 'Burgers',
      campaignId: 1,
      sortOrder: 2,
      parentId: 12,
      childrenCount: 1,
      productsCount: 1,
    },
    {
      id: 15,
      name: 'Espresso Drinks',
      displayName: 'Espresso Drinks',
      campaignId: 1,
      sortOrder: 3,
      parentId: 12,
      childrenCount: 1,
      productsCount: 1,
    },
    {
      id: 16,
      name: 'Cold Drinks',
      displayName: 'Cold Drinks',
      campaignId: 1,
      sortOrder: 4,
      parentId: 12,
      childrenCount: 1,
      productsCount: 1,
    },
    {
      id: 17,
      name: 'Sides',
      displayName: 'Sides',
      campaignId: 1,
      sortOrder: 5,
      parentId: 12,
      childrenCount: 1,
      productsCount: 1,
    },
  ],
  navigationsProducts: [
    {
      productCode: 1001,
      classificationCode: 2,
      navigationId: 14,
      campaignId: 6,
    },
  ],
  products: [
    {
      id: 5287397990334464,
      code: 1001,
      campaignId: 6,
      description: 'The authentic taste of a beef and blue cheese',
      allergens: [],
      dimensions: [],
      customParams: {},
      kernelParams: {},
      price: {
        id: 801,
        campaignId: 6,
        productCode: 1001,
        contextCode: null,
        pricesListId: 5210463449120768,
        computed: 1,
        defaultUnitPrice: 11,
        addedUnitPrice: 0,
        subtractedUnitPrice: 0,
        includedQuantity: 0,
      },
      imageUrl:
        'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/AMIfv975yK67m-hncMWHWUQPVjY0XA7a5bdlJVtLOPzGQi88hhSV6YcyIkVAmVBYFKIsRRkHt-I-OACUcgPkxjmOJDOz5s_e2F11K6dmmTO06fAJkIgdxi__dM5WtnfooWWJWIK3m6cWBZF1bEKfvHpxRQQslM5ag7qCw_mkwMyg9hCZbnjjFJY',
      isCustomizable: true,
      isEnabled: true,
      name: 'The Rustic',
      priority: 1,
      tags: ['Categories=Food', 'SubCategories=Beef'],
      type: ProductType.REGULAR,
      calories: 0,
      translations: [],
      defaultOptions: [],
    },
    {
      id: 5669794430844928,
      code: 3002,
      campaignId: 6,
      description: '.Whole Wheat Bun',
      allergens: [],
      dimensions: [],
      customParams: {},
      kernelParams: {},
      price: {
        id: 783,
        campaignId: 6,
        productCode: 3002,
        contextCode: 1,
        pricesListId: 6618151622017024,
        computed: 1,
        defaultUnitPrice: 0,
        addedUnitPrice: 0,
        subtractedUnitPrice: 0,
        includedQuantity: 0,
      },
      imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
      isCustomizable: true,
      isEnabled: true,
      name: '.Whole Wheat Bun',
      priority: 1,
      tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient', 'Kds_Consolidate'],
      type: ProductType.REGULAR,
      calories: 0,
      translations: [],
      defaultOptions: [],
    },
    {
      id: 5953591332306944,
      code: 3005,
      campaignId: 6,
      description: '.Beef Patty',
      allergens: [],
      dimensions: [],
      customParams: {},
      kernelParams: {},
      price: {
        id: 802,
        campaignId: 6,
        productCode: 3005,
        contextCode: 1,
        pricesListId: 6618151622017024,
        computed: 1,
        defaultUnitPrice: 0,
        addedUnitPrice: 0,
        subtractedUnitPrice: 0,
        includedQuantity: 0,
      },
      imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
      isCustomizable: true,
      isEnabled: true,
      name: '.Beef Patty',
      priority: 1,
      tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient', 'Kds_Consolidate'],
      type: ProductType.REGULAR,
      calories: 0,
      translations: [],
      defaultOptions: [],
    },
    {
      id: 5680531849084928,
      code: 3009,
      campaignId: 6,
      description: '.Arugula Salad',
      allergens: [],
      dimensions: [],
      customParams: {},
      kernelParams: {},
      price: {
        id: 799,
        campaignId: 6,
        productCode: 3009,
        contextCode: 1,
        pricesListId: 6618151622017024,
        computed: 1,
        defaultUnitPrice: 0,
        addedUnitPrice: 0,
        subtractedUnitPrice: 0,
        includedQuantity: 0,
      },
      imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
      isCustomizable: true,
      isEnabled: true,
      name: '.Arugula Salad',
      priority: 1,
      tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
      type: ProductType.REGULAR,
      calories: 0,
      translations: [],
      defaultOptions: [],
    },
    {
      id: 5656791484465152,
      code: 3010,
      campaignId: 6,
      description: '.Blue Cheese',
      allergens: [],
      dimensions: [],
      customParams: {},
      kernelParams: {},
      price: {
        id: 742,
        campaignId: 6,
        productCode: 3010,
        contextCode: 1,
        pricesListId: 6618151622017024,
        computed: 1,
        defaultUnitPrice: 0,
        addedUnitPrice: 0,
        subtractedUnitPrice: 0,
        includedQuantity: 0,
      },
      imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
      isCustomizable: true,
      isEnabled: true,
      name: '.Blue Cheese',
      priority: 1,
      tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
      type: ProductType.REGULAR,
      calories: 0,
      translations: [],
      defaultOptions: [],
    },
    {
      id: 5031964272754688,
      code: 3011,
      campaignId: 6,
      description: '.Dijon Mustard Sauce',
      allergens: [],
      dimensions: [],
      customParams: {},
      kernelParams: {},
      price: {
        id: 808,
        campaignId: 6,
        productCode: 3011,
        contextCode: 1,
        pricesListId: 6618151622017024,
        computed: 1,
        defaultUnitPrice: 0,
        addedUnitPrice: 0,
        subtractedUnitPrice: 0,
        includedQuantity: 0,
      },
      imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
      isCustomizable: true,
      isEnabled: true,
      name: '.Dijon Mustard Sauce',
      priority: 1,
      tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
      type: ProductType.REGULAR,
      calories: 0,
      translations: [],
      defaultOptions: [],
    },
    {
      id: 5005923013623808,
      code: 3012,
      campaignId: 6,
      description: '.Red Onions',
      allergens: [],
      dimensions: [],
      customParams: {},
      kernelParams: {},
      price: {
        id: 814,
        campaignId: 6,
        productCode: 3012,
        contextCode: 1,
        pricesListId: 6618151622017024,
        computed: 1,
        defaultUnitPrice: 0,
        addedUnitPrice: 0,
        subtractedUnitPrice: 0,
        includedQuantity: 0,
      },
      imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
      isCustomizable: true,
      isEnabled: true,
      name: '.Red Onions',
      priority: 1,
      tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
      type: ProductType.REGULAR,
      calories: 0,
      translations: [],
      defaultOptions: [],
    },
    {
      id: 5106844477423616,
      code: 3013,
      campaignId: 6,
      description: '.Caramelized Onions',
      allergens: [],
      dimensions: [],
      customParams: {},
      kernelParams: {},
      price: {
        id: 722,
        campaignId: 6,
        productCode: 3013,
        contextCode: 1,
        pricesListId: 6618151622017024,
        computed: 1,
        defaultUnitPrice: 0,
        addedUnitPrice: 0,
        subtractedUnitPrice: 0,
        includedQuantity: 0,
      },
      imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
      isCustomizable: true,
      isEnabled: true,
      name: '.Caramelized Onions',
      priority: 1,
      tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
      type: ProductType.REGULAR,
      calories: 0,
      translations: [],
      defaultOptions: [],
    },
  ],
  productsParts: [
    {
      productCode: 1001,
      partCode: 3002,
      defaultQuantity: 1,
      includedQuantity: 1,
      maxQuantity: 1,
      minQuantity: 1,
      type: ProductPartType.UNIQUE,
      product: {
        defaultOptions: [],
        id: 5669794430844928,
        code: 3002,
        campaignId: 6,
        description: '.Whole Wheat Bun',
        allergens: [],
        dimensions: [],
        customParams: {},
        kernelParams: {},
        price: {
          id: 783,
          campaignId: 6,
          productCode: 3002,
          contextCode: 1,
          pricesListId: 6618151622017024,
          computed: 1,
          defaultUnitPrice: 0,
          addedUnitPrice: 0,
          subtractedUnitPrice: 0,
          includedQuantity: 0,
        },
        imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
        isCustomizable: true,
        isEnabled: true,
        name: '.Whole Wheat Bun',
        priority: 1,
        tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient', 'Kds_Consolidate'],
        type: ProductType.REGULAR,
        calories: 0,
        translations: [],
        parts: [],
      },
    },
    {
      productCode: 1001,
      partCode: 3005,
      defaultQuantity: 1,
      includedQuantity: 1,
      maxQuantity: 2,
      minQuantity: 0,
      type: ProductPartType.UNIQUE,
      product: {
        defaultOptions: [],
        id: 5953591332306944,
        code: 3005,
        campaignId: 6,
        description: '.Beef Patty',
        allergens: [],
        dimensions: [],
        customParams: {},
        kernelParams: {},
        price: {
          id: 802,
          campaignId: 6,
          productCode: 3005,
          contextCode: 1,
          pricesListId: 6618151622017024,
          computed: 1,
          defaultUnitPrice: 0,
          addedUnitPrice: 0,
          subtractedUnitPrice: 0,
          includedQuantity: 0,
        },
        imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
        isCustomizable: true,
        isEnabled: true,
        name: '.Beef Patty',
        priority: 1,
        tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient', 'Kds_Consolidate'],
        type: ProductType.REGULAR,
        calories: 0,
        translations: [],
        parts: [],
      },
    },
    {
      productCode: 1001,
      partCode: 3009,
      defaultQuantity: 1,
      includedQuantity: 1,
      maxQuantity: 2,
      minQuantity: 0,
      type: ProductPartType.UNIQUE,
      product: {
        defaultOptions: [],
        id: 5680531849084928,
        code: 3009,
        campaignId: 6,
        description: '.Arugula Salad',
        allergens: [],
        dimensions: [],
        customParams: {},
        kernelParams: {},
        price: {
          id: 799,
          campaignId: 6,
          productCode: 3009,
          contextCode: 1,
          pricesListId: 6618151622017024,
          computed: 1,
          defaultUnitPrice: 0,
          addedUnitPrice: 0,
          subtractedUnitPrice: 0,
          includedQuantity: 0,
        },
        imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
        isCustomizable: true,
        isEnabled: true,
        name: '.Arugula Salad',
        priority: 1,
        tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
        type: ProductType.REGULAR,
        calories: 0,
        translations: [],
        parts: [],
      },
    },
    {
      productCode: 1001,
      partCode: 3010,
      defaultQuantity: 2,
      includedQuantity: 2,
      maxQuantity: 4,
      minQuantity: 0,
      type: ProductPartType.UNIQUE,
      product: {
        defaultOptions: [],
        id: 5656791484465152,
        code: 3010,
        campaignId: 6,
        description: '.Blue Cheese',
        allergens: [],
        dimensions: [],
        customParams: {},
        kernelParams: {},
        price: {
          id: 742,
          campaignId: 6,
          productCode: 3010,
          contextCode: 1,
          pricesListId: 6618151622017024,
          computed: 1,
          defaultUnitPrice: 0,
          addedUnitPrice: 0,
          subtractedUnitPrice: 0,
          includedQuantity: 0,
        },
        imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
        isCustomizable: true,
        isEnabled: true,
        name: '.Blue Cheese',
        priority: 1,
        tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
        type: ProductType.REGULAR,
        calories: 0,
        translations: [],
        parts: [],
      },
    },
    {
      productCode: 1001,
      partCode: 3011,
      defaultQuantity: 1,
      includedQuantity: 1,
      maxQuantity: 3,
      minQuantity: 0,
      type: ProductPartType.UNIQUE,
      product: {
        defaultOptions: [],
        id: 5031964272754688,
        code: 3011,
        campaignId: 6,
        description: '.Dijon Mustard Sauce',
        allergens: [],
        dimensions: [],
        customParams: {},
        kernelParams: {},
        price: {
          id: 808,
          campaignId: 6,
          productCode: 3011,
          contextCode: 1,
          pricesListId: 6618151622017024,
          computed: 1,
          defaultUnitPrice: 0,
          addedUnitPrice: 0,
          subtractedUnitPrice: 0,
          includedQuantity: 0,
        },
        imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
        isCustomizable: true,
        isEnabled: true,
        name: '.Dijon Mustard Sauce',
        priority: 1,
        tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
        type: ProductType.REGULAR,
        calories: 0,
        translations: [],
        parts: [],
      },
    },
    {
      productCode: 1001,
      partCode: 3012,
      defaultQuantity: 1,
      includedQuantity: 1,
      maxQuantity: 3,
      minQuantity: 0,
      type: ProductPartType.UNIQUE,
      product: {
        defaultOptions: [],
        id: 5005923013623808,
        code: 3012,
        campaignId: 6,
        description: '.Red Onions',
        allergens: [],
        dimensions: [],
        customParams: {},
        kernelParams: {},
        price: {
          id: 814,
          campaignId: 6,
          productCode: 3012,
          contextCode: 1,
          pricesListId: 6618151622017024,
          computed: 1,
          defaultUnitPrice: 0,
          addedUnitPrice: 0,
          subtractedUnitPrice: 0,
          includedQuantity: 0,
        },
        imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
        isCustomizable: true,
        isEnabled: true,
        name: '.Red Onions',
        priority: 1,
        tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
        type: ProductType.REGULAR,
        calories: 0,
        translations: [],
        parts: [],
      },
    },
    {
      productCode: 1001,
      partCode: 3013,
      defaultQuantity: 1,
      includedQuantity: 1,
      maxQuantity: 3,
      minQuantity: 0,
      type: ProductPartType.UNIQUE,
      product: {
        defaultOptions: [],
        id: 5106844477423616,
        code: 3013,
        campaignId: 6,
        description: '.Caramelized Onions',
        allergens: [],
        dimensions: [],
        customParams: {},
        kernelParams: {},
        price: {
          id: 722,
          campaignId: 6,
          productCode: 3013,
          contextCode: 1,
          pricesListId: 6618151622017024,
          computed: 1,
          defaultUnitPrice: 0,
          addedUnitPrice: 0,
          subtractedUnitPrice: 0,
          includedQuantity: 0,
        },
        imageUrl: 'https://acrelec-central-pos.uc.r.appspot.com/api/serve_media/undefined',
        isCustomizable: true,
        isEnabled: true,
        name: '.Caramelized Onions',
        priority: 1,
        tags: ['Categories=Food', 'SubCategories=Ingredients', 'Is_Ingredient'],
        type: ProductType.REGULAR,
        calories: 0,
        translations: [],
        parts: [],
      },
    },
  ],
  productsPrices: [
    {
      id: 801,
      campaignId: 6,
      productCode: 1001,
      contextCode: null,
      pricesListId: 5210463449120768,
      computed: 1,
      defaultUnitPrice: 11,
      addedUnitPrice: 0,
      subtractedUnitPrice: 0,
      includedQuantity: 0,
    },
    {
      id: 722,
      campaignId: 6,
      productCode: 3013,
      contextCode: 1,
      pricesListId: 6618151622017024,
      computed: 1,
      defaultUnitPrice: 0,
      addedUnitPrice: 0,
      subtractedUnitPrice: 0,
      includedQuantity: 0,
    },
    {
      id: 814,
      campaignId: 6,
      productCode: 3012,
      contextCode: 1,
      pricesListId: 6618151622017024,
      computed: 1,
      defaultUnitPrice: 0,
      addedUnitPrice: 0,
      subtractedUnitPrice: 0,
      includedQuantity: 0,
    },
    {
      id: 742,
      campaignId: 6,
      productCode: 3010,
      contextCode: 1,
      pricesListId: 6618151622017024,
      computed: 1,
      defaultUnitPrice: 0,
      addedUnitPrice: 0,
      subtractedUnitPrice: 0,
      includedQuantity: 0,
    },
    {
      id: 808,
      campaignId: 6,
      productCode: 3011,
      contextCode: 1,
      pricesListId: 6618151622017024,
      computed: 1,
      defaultUnitPrice: 0,
      addedUnitPrice: 0,
      subtractedUnitPrice: 0,
      includedQuantity: 0,
    },
    {
      id: 802,
      campaignId: 6,
      productCode: 3005,
      contextCode: 1,
      pricesListId: 6618151622017024,
      computed: 1,
      defaultUnitPrice: 0,
      addedUnitPrice: 0,
      subtractedUnitPrice: 0,
      includedQuantity: 0,
    },
    {
      id: 783,
      campaignId: 6,
      productCode: 3002,
      contextCode: 1,
      pricesListId: 6618151622017024,
      computed: 1,
      defaultUnitPrice: 0,
      addedUnitPrice: 0,
      subtractedUnitPrice: 0,
      includedQuantity: 0,
    },
    {
      id: 799,
      campaignId: 6,
      productCode: 3009,
      contextCode: 1,
      pricesListId: 6618151622017024,
      computed: 1,
      defaultUnitPrice: 0,
      addedUnitPrice: 0,
      subtractedUnitPrice: 0,
      includedQuantity: 0,
    },
  ],
  productsClassifications: [],
};
