import {
  StartPredictRequest,
  PredictStatusObject,
  EndingStatusObject,
  CustomMessageRequest,
  UpdatePredictRequest,
} from '@acrelec-cloud/apico-shared';
import axios, { AxiosInstance } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { ApicoSDK } from '../../apico-sdk';

export class PredictService {
  protected readonly axiosInstance: AxiosInstance;

  constructor(protected readonly sdk: ApicoSDK) {
    this.axiosInstance = axios.create({
      baseURL: sdk.getEndpoint(),
      withCredentials: true,
    });
    axiosBetterStacktrace(this.axiosInstance);
  }

  async startPredict(startPredictData: StartPredictRequest): Promise<PredictStatusObject> {
    return this.axiosInstance
      .post(`/predict/start`, startPredictData)
      .then(response => response.data);
  }
  async updatePredict(updatePredictData: UpdatePredictRequest): Promise<PredictStatusObject> {
    return this.axiosInstance
      .post(`/predict/update`, updatePredictData)
      .then(response => response.data);
  }
  async sendCustomMessage(customMessageData: CustomMessageRequest): Promise<EndingStatusObject> {
    return this.axiosInstance
      .post(`/predict/message`, customMessageData)
      .then(response => response.data);
  }
  async cancelPredict(sessionId: string): Promise<EndingStatusObject> {
    return this.axiosInstance.post(`/predict/cancel`, sessionId).then(response => response.data);
  }
  async predictHealthCheck(): Promise<EndingStatusObject> {
    return this.axiosInstance.get(`/predict/health-check`).then(response => response.data);
  }
  async predictRestaurantCheck(predictStoreCode: string): Promise<EndingStatusObject> {
    return this.axiosInstance
      .get(`/predict/restaurant-check/${predictStoreCode}`)
      .then(response => response.data);
  }
}
