import { AuthenticationService } from './services/authentication/authentication.service';
import { ContentsService } from './services/contents/contents.service';
import { GuestsService } from './services/guests/guests.service';
import { OrdersService } from './services/orders/orders.service';
import { PaymentsService } from './services/payments/payments.service';
import { PredictService } from './services/predict/predict.service';
import { RestaurantsService } from './services/restaurants/restaurants.service';
import { UsersService } from './services/users/users.service';

/**
 * Initialize options for ApicoSDK
 */
export interface ApicoSDKOptions {
  /**
   * Base APICO Endpoint.
   */
  endpoint?: string;

  /**
   * Defaults values used by the services.
   */
  defaults?: {
    /**
     * Default current restaurant.
     */
    restaurantId?: number;
    /**
     * Default campaign.
     */
    campaignId?: number;
  };

  /**
   * Override a service declaration.
   * Can be used to create different process or add mocks on specific services only
   */
  servicesOverrides?: {
    contents?: (sdk: ApicoSDK) => ContentsService;
    restaurants?: (sdk: ApicoSDK) => RestaurantsService;
    orders?: (sdk: ApicoSDK) => OrdersService;
    authentication?: (sdk: ApicoSDK) => AuthenticationService;
    guests?: (sdk: ApicoSDK) => GuestsService;
    users?: (sdk: ApicoSDK) => UsersService;
    payments?: (sdk: ApicoSDK) => PaymentsService;
    predict?: (sdk: ApicoSDK) => PredictService;
  };
}

/**
 * APICO SDK used to request the APICO Api.
 */
export class ApicoSDK {
  /**
   * User account token (setUp with auth.login)
   */
  private accessToken?: string;

  /**
   * Url of APICO
   */
  private options: ApicoSDKOptions;

  /**
   * To get information about Restaurants
   */
  public restaurants: RestaurantsService;

  /**
   * To get all content information like Navigations and Products
   */
  public contents: ContentsService;

  public orders: OrdersService;

  public authentication: AuthenticationService;

  public guests: GuestsService;

  public users: UsersService;

  public payments: PaymentsService;

  public predict: PredictService;

  constructor(options?: ApicoSDKOptions) {
    this.options = options || {};
    this.options.defaults = this.options.defaults || {};

    this.contents = this.options.servicesOverrides?.contents
      ? this.options.servicesOverrides?.contents(this)
      : new ContentsService(this);

    this.restaurants = this.options.servicesOverrides?.restaurants
      ? this.options.servicesOverrides?.restaurants(this)
      : new RestaurantsService(this);

    this.orders = this.options.servicesOverrides?.orders
      ? this.options.servicesOverrides?.orders(this)
      : new OrdersService(this);

    this.authentication = this.options.servicesOverrides?.authentication
      ? this.options.servicesOverrides?.authentication(this)
      : new AuthenticationService(this);

    this.guests = this.options.servicesOverrides?.guests
      ? this.options.servicesOverrides?.guests(this)
      : new GuestsService(this);

    this.users = this.options.servicesOverrides?.users
      ? this.options.servicesOverrides?.users(this)
      : new UsersService(this);

    this.payments = this.options.servicesOverrides?.payments
      ? this.options.servicesOverrides?.payments(this)
      : new PaymentsService(this);

    this.predict = this.options.servicesOverrides?.predict
      ? this.options.servicesOverrides?.predict(this)
      : new PredictService(this);
  }

  /**
   * Get endpoint of the api.
   */
  getEndpoint(): string | undefined {
    return this.options.endpoint;
  }

  /**
   * Get defaults options for the api.
   */
  getDefaults(): ApicoSDKOptions['defaults'] {
    return this.options.defaults;
  }

  /**
   * To save in SDK the default Restaurant
   * @param {number} restaurantId Id of selected Restaurant
   */
  setDefaultRestaurant(restaurantId: number): void {
    this.options.defaults = {
      ...this.options.defaults,
      restaurantId,
    };
  }

  /**
   * Add accessToken in SDK process
   * @param accessToken token from Login
   */
  setAccessToken(accessToken: string): void {
    this.accessToken = accessToken;
  }

  /**
   * Delete the accessToken of the SDK
   */
  deleteAccessToken(): void {
    this.accessToken = undefined;
  }
}
