import { Type } from 'class-transformer';

/**
 * Represent an user.
 */
export class UserObject {
  /**
   * Id of the account.
   *
   * @type {number}
   * @memberof UserObject
   */
  @Type(() => Number)
  id: number;

  /**
   * Id of the account related to the user.
   *
   * @type {number}
   * @memberof UserObject
   */
  @Type(() => Number)
  accountId: number;

  /**
   * Email of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  email: string;

  /**
   * First name of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  firstName: string;

  /**
   * Last name of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  lastName: string;

  /**
   * Address of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  addressLine1: string;

  /**
   * Address of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  addressLine2?: string;

  /**
   * Complementary data address of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  addressComplementary?: string;

  /**
   * Postal code of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  postalCode: string;

  /**
   * City of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  city: string;

  /**
   * Language of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  language: string;

  /**
   * Whether user has accepted terms of use.
   *
   * @type {boolean}
   * @memberof UserObject
   */
  isTermsAccepted: boolean;

  /**
   * Phone number of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  phoneNumber?: string;
}
