import { IsInt, IsNotEmpty, IsNumber, IsPositive, IsString } from 'class-validator';

export class AuthenticateGuestRequest {
  @IsNumber()
  @IsInt()
  @IsPositive()
  guestId: number;

  @IsString()
  @IsNotEmpty()
  deviceToken: string;
}
