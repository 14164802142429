import { Type } from 'class-transformer';

export class TaxCategoryObject {
  @Type(() => Number)
  id: number;

  @Type(() => Number)
  campaignId: number;

  isTaxed: boolean;

  description: string;
}
