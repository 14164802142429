import { Type } from 'class-transformer';
import { Min, Max, IsInt } from 'class-validator';

/**
 * Rate an order.
 */
export class RateOrderRequest {
  /**
   * Rating of the order, between 0 and 5 included.
   *
   * @type {Number}
   * @memberof RateOrderRequest
   */
  @Type(() => Number)
  @IsInt()
  @Min(0)
  @Max(5)
  rating: number;
}
