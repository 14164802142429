import { IsEmail, IsString } from 'class-validator';

export class CreateUserRequest {
  /**
   * Email of the user.
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  @IsEmail()
  email: string;

  /**
   * Language of the user.
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  @IsString()
  language: string;
}
