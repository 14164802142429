import { Type } from 'class-transformer';
import { IsNumber, IsOptional, IsPositive, IsString, Min } from 'class-validator';

export class FindAccountsRequest {
  /**
   * number of elements displayed
   * @default 20
   */
  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsOptional()
  limit = 20;

  /**
   * number of elements skipped
   * @default 0
   */
  @Type(() => Number)
  @IsNumber()
  @Min(0)
  @IsOptional()
  skip = 0;

  /**
   * custom search
   */
  @Type(() => Number)
  @IsString()
  @IsOptional()
  search?: string;

  /**
   * sort by index, price && ascending or descending etc.
   */
  @Type(() => Number)
  @IsString()
  @IsOptional()
  sortBy?: string;
}
