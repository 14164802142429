import { Type } from 'class-transformer';

import { GuestObject } from '../../guests/objects/guest.object';
import { UserObject } from '../../users/objects/user.object';
import { AccountObject } from './account.object';

/**
 * Represent an authenticated account. Used as response of current authenticated request.
 */
export class AuthenticatedAccountObject {
  /**
   * Authenticated account.
   *
   * @type {AccountObject}
   * @memberof AuthenticatedAccountObject
   */
  @Type(() => AccountObject)
  account: AccountObject;

  /**
   * Authenticated user if the account type is user.
   *
   * @type {UserObject}
   * @memberof AuthenticatedAccountObject
   */
  @Type(() => UserObject)
  user?: UserObject;

  /**
   * Authenticated guest if the account type is guest.
   *
   * @type {GuestObject}
   * @memberof AuthenticatedAccountObject
   */
  @Type(() => GuestObject)
  guest?: GuestObject;
}
