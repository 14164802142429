import { Equals, IsBoolean, IsEmail, IsOptional, IsString } from 'class-validator';

export class CreateGuestRequest {
  /**
   * Email of the created guest.
   *
   * @type {string}
   * @memberof CreateGuestRequest
   */
  @IsEmail()
  email: string;

  /**
   * First name of the created guest.
   *
   * @type {string}
   * @memberof CreateGuestRequest
   */
  @IsString()
  firstName: string;

  /**
   * Last Name of the created guest.
   *
   * @type {string}
   * @memberof CreateGuestRequest
   */
  @IsString()
  lastName: string;

  /**
   * Device token used for authentication.
   *
   * @type {string}
   * @memberof CreateGuestRequest
   */
  @IsString()
  deviceToken: string;

  /**
   * Language of the created guest
   *
   * @type {string}
   * @memberof CreateGuestRequest
   */
  @IsString()
  language: string;

  /**
   * Whether guest has accepted terms of use.
   *
   * @type {boolean}
   * @memberof CreateGuestRequest
   */
  @IsBoolean()
  @Equals(true)
  isTermsAccepted: boolean;

  /**
   * Whether guest has accepted the data protection policy
   *
   * @type {boolean}
   * @memberof CreateGuestRequest
   */
  @IsBoolean()
  isPolicyAccepted: boolean;

  /**
   *  Whether guest has accepted to receive offers and news
   *
   * @type {boolean}
   * @memberof CreateGuestRequest
   */
  @IsBoolean()
  isOffersAccepted: boolean;

  /**
   * phoneNumber of the created guest
   *
   * @type {string}
   * @memberof CreateGuestRequest
   */
  @IsOptional()
  @IsString()
  phoneNumber?: string;
}
