import { Type } from 'class-transformer';

import { PaginatedResponse } from '../../common/paginated-response';
import { RestaurantObject } from './restaurant.object';

export class PaginatedRestaurantsObject implements PaginatedResponse<RestaurantObject> {
  /**
   * list of elements
   */
  @Type(() => RestaurantObject)
  items: RestaurantObject[];

  /**
   * number of total elements
   */
  count: number;

  /**
   * number to display
   */
  limit: number;

  /**
   * number to skip
   */
  skip: number;
}
