import { Type } from 'class-transformer';

import { TaxCategoryObject } from './tax-category.object';
import { TaxRuleObject } from './tax-rule.object';
import { TaxObject } from './tax.object';

export class ProductTaxObject {
  @Type(() => TaxCategoryObject)
  taxCategory: TaxCategoryObject;
  @Type(() => TaxObject)
  tax?: TaxObject;
  @Type(() => TaxRuleObject)
  taxRule?: TaxRuleObject;
}
