import { Type } from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

import { ProductPartObject } from '../../contents/objects/product-part.object';
import { ProductObject } from '../../contents/objects/product.object';

/**
 * Represent an item inside an order.
 */
export class OrderItemObject {
  /**
   * Unique id of the item.
   *
   * @type {string}
   * @memberof OrderItemObject
   */
  @IsString()
  id: string;

  /**
   * Code of the product.
   *
   * @type {number}
   * @memberof OrderItemObject
   */
  @IsNumber()
  productCode: number;

  /**
   * Price context of the product
   *
   * @type {number}
   * @memberof OrderItemObject
   */
  @IsNumber()
  @IsOptional()
  contextCode: number;

  /**
   * Cold version of the product in case of deleted product.
   *
   * @type {ProductObject}
   * @memberof OrderItemObject
   */
  @Type(() => ProductObject)
  @IsObject()
  productSnapshot: ProductObject;

  /**
   * Part information of the product if relevant.
   *
   * @type {ProductPartObject[]}
   * @memberof OrderItemObject
   */
  @Type(() => ProductPartObject)
  @IsObject()
  @IsOptional()
  productPart?: ProductPartObject;

  /**
   * Quantity of the item.
   *
   * @type {number}
   * @memberof OrderItemObject
   */
  @IsNumber()
  quantity: number;

  /**
   * Price of the item.
   *
   * @type {number}
   * @memberof OrderItemObject
   */
  @IsNumber()
  price: number;

  /**
   * Taxes of the item.
   *
   * @type {number}
   * @memberof OrderItemObject
   */
  @IsNumber()
  tax: number;

  /**
   * Children of the product. Could be modifiers or combo options.
   *
   * @type {OrderItemObject[]}
   * @memberof OrderItemObject
   */
  @Type(() => OrderItemObject)
  @ValidateNested()
  @IsArray()
  children: OrderItemObject[];

  /**
   * Item is an outage
   *
   * @type {boolean}
   * @memberof OrderItemObject
   */
  @IsBoolean()
  @IsOptional()
  isOutaged?: boolean;
}
