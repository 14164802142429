import { Type } from 'class-transformer';
import { IsString, IsOptional } from 'class-validator';

import { MessageDetailsObject } from './message-details.request';

export class CustomMessageRequest {
  /**
   * The session id
   *
   * @type {string}
   * @memberof CustomMessageRequest
   */
  @IsString()
  @IsOptional()
  SessionId: string;

  /**
   * location of the restaurant
   * @memberof CustomMessageRequest
   */
  @Type(() => MessageDetailsObject)
  @IsOptional()
  CustomMessageDetails: MessageDetailsObject;
}
