/**
 * Describe pricing of a product.
 */
export class ProductPriceObject {
  /**
   * Id of the price
   *
   * @type {number}
   * @memberof ProductPriceObject
   */
  id: number;

  /**
   * Id of the compaign
   *
   * @type {number}
   * @memberof ProductPriceObject
   */
  campaignId: number;

  /**
   * Code of the product owning the price.
   *
   * @type {number}
   * @memberof ProductPriceObject
   */
  productCode: number;

  /**
   * Code of the product for the context of the price. Used to define what price need to be applied on the product.
   * Empty context mean default price for the product.
   *
   * @type {number}
   * @memberof ProductPriceObject
   */
  contextCode: number | null;

  /**
   * Id of the price list.
   *
   * @type {number}
   * @memberof ProductPriceObject
   */
  pricesListId: number;

  computed: number;

  /**
   * Default price for the product.
   *
   * @type {number}
   * @memberof ProductPriceObject
   */
  defaultUnitPrice: number;

  /**
   * Unit price substracted when removed item.
   *
   * @type {(number | null)}
   * @memberof ProductPriceObject
   */
  subtractedUnitPrice: number | null;

  /**
   * Unit price substracted when removed item.
   *
   * @type {(number | null)}
   * @memberof ProductPriceObject
   */
  addedUnitPrice: number | null;

  /**
   * Included number of units in this pricing system.
   *
   * @type {number}
   * @memberof ProductPriceObject
   */
  includedQuantity: number;
}
