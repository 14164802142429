import { IsBoolean, IsString, IsNumber, IsPositive, IsOptional } from 'class-validator';

export class UpdateSettingsRequest {
  /**
   * Whether Restaurant can take orders
   *
   * @type {boolean}
   * @memberof UpdateSettingsRequest
   */
  @IsBoolean()
  @IsOptional()
  ordersEnabled?: boolean;

  /**
   * Delay before orders are injected, in minutes
   *
   * @type {number}
   * @memberof UpdateSettingsRequest
   */
  @IsNumber()
  @IsPositive()
  @IsOptional()
  ordersInjectionDelay?: number;

  /**
   * Merchant ID for Nepting
   *
   * @type {string}
   * @memberof UpdateSettingsRequest
   */
  @IsString()
  @IsOptional()
  merchantId?: string;

  /**
   * Restaurant's email
   *
   * @type {string}
   * @memberof UpdateSettingsRequest
   */
  @IsString()
  @IsOptional()
  restaurantEmail?: string;

  /**
   * Break of injection, in minutes
   *
   * @type {number}
   * @memberof UpdateSettingsRequest
   */
  @IsNumber()
  @IsOptional()
  disableOrders?: number;

  /**
   * Sign key for Nepting
   *
   * @type {string}
   * @memberof UpdateSettingsRequest
   */
  @IsString()
  @IsOptional()
  signKey?: string;

  /**
   * Outage URL
   *
   * @type {string}
   * @memberof UpdateSettingsRequest
   */
  @IsString()
  @IsOptional()
  outageURL?: string;
  /**
   * Predict Store code
   *
   * @type {number}
   * @memberof UpdateSettingsRequest
   */
  @IsString()
  @IsOptional()
  predictStoreCode?: string;
}
