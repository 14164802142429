import { Type } from 'class-transformer';

/**
 * Represent a guest.
 */
export class GuestObject {
  /**
   * Id of the account.
   *
   * @type {number}
   * @memberof GuestObject
   */
  @Type(() => Number)
  id: number;

  /**
   * Id of the account related to the user.
   *
   * @type {number}
   * @memberof GuestObject
   */
  @Type(() => Number)
  accountId: number;

  /**
   * Email of the guest.
   *
   * @type {string}
   * @memberof GuestObject
   */
  email: string;

  /**
   * First Name of the guest.
   *
   * @type {string}
   * @memberof GuestObject
   */
  firstName: string;

  /**
   * Last Name of the guest.
   *
   * @type {string}
   * @memberof GuestObject
   */
  lastName: string;

  /**
   * Token of the device hosting the guest. Used for authentication.
   *
   * @type {string}
   * @memberof GuestEntity
   */
  deviceToken: string;

  /**
   * Language of the guest.
   *
   * @type {string}
   * @memberof GuestObject
   */
  language: string;

  /**
   * Whether guest has accepted terms of use.
   *
   * @type {boolean}
   * @memberof GuestObject
   */
  isTermsAccepted: boolean;

  /**
   * Phone number of the user.
   *
   * @type {string}
   * @memberof UserObject
   */
  phoneNumber?: string;
}
