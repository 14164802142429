import { Type } from 'class-transformer';

import type { ProductClassificationObject } from './product-classification.object';
import { ProductDimensionObject } from './product-dimension.object';
import type { ProductPartObject } from './product-part.object';
import { ProductPriceObject } from './product-price.object';
import { ProductTaxObject } from './product-tax.object';
import { ProductTranslationObject } from './product-translation.object';

/**
 * Type of a product
 */
export enum ProductType {
  REGULAR = 'REGULAR',
  OPTION = 'OPTION',
  COMBO = 'COMBO',
  COUPON = 'COUPON',
}

/**
 * Describe a product inside a navigation
 */
export class ProductObject {
  /** Product id */
  id: number;

  /** Product code */
  code: number;

  /** Campaign id */
  campaignId: number;

  /** Product name */
  name: string;

  /** Product description */
  description: string;

  /** Product type */
  type: ProductType;

  /** Product image url */
  imageUrl: string;

  /** Product tags */
  tags: string[];

  /** Product priority. Used to order products inside a navigation. */
  priority: number;

  /** If the product is available in the restaurant */
  isEnabled: boolean;

  /** Product allergens list */
  allergens: string[];

  /** Product calories */
  calories: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customParams: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  kernelParams: any;

  /** Product dimensions */
  @Type(() => ProductDimensionObject)
  dimensions: ProductDimensionObject[];

  /** List of translations */
  @Type(() => ProductTranslationObject)
  translations: ProductTranslationObject[];

  /** Price of product in the actual context */
  @Type(() => ProductPriceObject)
  price: ProductPriceObject | null;

  /** Percent of tax 0<1 => 0% -> 100% */
  tax?: ProductTaxObject;

  /** Is the product customizeable */
  isCustomizable: boolean;

  /**
   * Product codes of the defaults options.
   * Only used when the product type is `OPTION`.
   *
   * @type {number[]}
   * @memberof ProductObject
   */
  defaultOptions: number[];

  /**
   * Parts of this product.
   *
   * @deprecated Currently filled by the server, will be deleted after the MVP
   * @type {ProductPartObject[]}
   * @memberof ProductObject
   */
  parts?: ProductPartObject[];

  /**
   * Options of this product. Only used when the product type is `OPTION`.
   *
   * @deprecated Currently filled by the server, will be deleted after the MVP
   * @type {ProductObject[]}
   * @memberof ProductObject
   */
  options?: ProductClassificationObject[];
}
