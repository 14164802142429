import { RestaurantsMocksDatabase } from '../restaurants.mocks';

export const restaurantsDatabase: RestaurantsMocksDatabase = {
  restaurants: [
    {
      id: 1,
      slug: 'chicago-west-loop',
      posStoreId: 1,
      currentCampaignId: 1,
      contextId: 3,
      name: 'Chicago West loop',
      address: {
        phone: '+074 453 423 334',
        number: '4359',
        street: '5th Avenue',
        zipCode: '60611',
        city: 'Chicago',
        county: 'Cook',
        state: 'Illinois',
        country: 'US',
      },
      currency: 'usd',
      rootNavigation: 'Online',
      geolocation: {
        latitude: 41.882611,
        longitude: -87.660903,
      },
      information: 'Info blablabla',
      previewUrl: 'https://www.journaldugeek.com/content/uploads/2017/02/google-maps.jpg',
      isOpen: true,
      timeZone: 'Europe/Paris',
      openingHours: [
        { name: 'THURSDAY', value: '10:00|21:59' },
        { name: 'MONDAY', value: '10:00|21:59' },
        { name: 'TUESDAY', value: '10:00|21:59' },
        { name: 'SUNDAY', value: '11:00|01:59' },
        { name: 'WEDNESDAY', value: '10:00|21:59' },
        { name: 'SATURDAY', value: '11:00|01:59' },
        { name: 'FRIDAY', value: '10:00|00:59' },
      ],
      authenticationRequired: false,
      outageProducts: [],
      settings: {
        ordersEnabledAt: new Date(),
        ordersEnabled: true,
        ordersInjectionDelay: 15,
        merchantId: '391_test',
        restaurantEmail: 'test@koji.fr',
      },
      links: {},
    },
    {
      id: 2,
      slug: 'paris-republique-lab',
      timeZone: 'Europe/Paris',
      posStoreId: 1,
      currentCampaignId: 1,
      contextId: 3,
      name: 'Paris - République Lab',
      address: {
        phone: '+33 6 55 33 66 77',
        number: '49',
        street: 'Rue du bla bla pookie',
        zipCode: '75018',
        city: 'Paris',
        county: '',
        state: '',
        country: 'France',
      },
      currency: 'usd',
      rootNavigation: 'Online',
      geolocation: {
        latitude: 4.8682228,
        longitude: 26.3638221,
      },
      information: 'Info more and more and more blabla',
      previewUrl: 'https://www.journaldugeek.com/content/uploads/2017/02/google-maps.jpg',
      isOpen: true,
      openingHours: [
        { name: 'THURSDAY', value: '10:00|21:59' },
        { name: 'MONDAY', value: '10:00|21:59' },
        { name: 'TUESDAY', value: '10:00|21:59' },
        { name: 'SUNDAY', value: '11:00|01:59' },
        { name: 'WEDNESDAY', value: '10:00|21:59' },
        { name: 'SATURDAY', value: '11:00|01:59' },
        { name: 'FRIDAY', value: '10:00|00:59' },
      ],
      authenticationRequired: false,
      outageProducts: [],
      settings: {
        ordersEnabledAt: new Date(),
        ordersEnabled: false,
        ordersInjectionDelay: 0,
        merchantId: '391_test',
        restaurantEmail: 'test@koji.fr',
      },
      links: {},
    },
    {
      id: 3,
      slug: 'paris-chateau-deau',
      posStoreId: 1,
      currentCampaignId: 1,
      contextId: 3,
      name: "Paris - Chateau d'eau",
      timeZone: 'Europe/Paris',
      address: {
        phone: '+33 4 75 32 55 66',
        number: '4',
        street: 'Rue Gustav Toudouze',
        zipCode: '75009',
        city: 'Paris',
        county: '',
        state: '',
        country: 'France',
      },
      currency: 'usd',
      rootNavigation: 'Online',
      geolocation: {
        latitude: 18.8723186,
        longitude: 6.35581863,
      },
      information: 'Info kmlvjoùqizgnùlsvjihobms',
      previewUrl: 'https://www.journaldugeek.com/content/uploads/2017/02/google-maps.jpg',
      isOpen: true,
      openingHours: [
        { name: 'THURSDAY', value: '10:00|21:59' },
        { name: 'MONDAY', value: '10:00|21:59' },
        { name: 'TUESDAY', value: '10:00|21:59' },
        { name: 'SUNDAY', value: '11:00|01:59' },
        { name: 'WEDNESDAY', value: '10:00|21:59' },
        { name: 'SATURDAY', value: '11:00|01:59' },
        { name: 'FRIDAY', value: '10:00|00:59' },
      ],
      authenticationRequired: false,
      outageProducts: [],
      settings: {
        ordersEnabledAt: new Date(),
        ordersEnabled: true,
        ordersInjectionDelay: 30,
        merchantId: '391_test',
        restaurantEmail: 'test@koji.fr',
      },
      links: {},
    },
  ],
};
