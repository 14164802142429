import { IsNumber, IsOptional, IsPositive } from 'class-validator';

export class CreateNeptingPaymentRequest {
  /**
   * Order related to this payment.
   *
   * @type {number}
   * @memberof CreateNeptingPaymentRequest
   */
  @IsNumber()
  @IsPositive()
  orderId: number;

  /**
   * Id of the payment method.
   *
   * @type {number}
   * @memberof CreateNeptingPaymentRequest
   */
  @IsOptional()
  @IsNumber()
  @IsPositive()
  paymentMethodId?: number;
}
