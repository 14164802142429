import { IsNumber, IsPositive } from 'class-validator';

export class CreateStripePaymentRequest {
  /**
   * Order related to this payment.
   *
   * @type {number}
   * @memberof CreateStripePaymentRequest
   */
  @IsNumber()
  @IsPositive()
  orderId: number;
}
