/**
 * Type of a coupon
 */
export enum CouponType {
  ORDER = 'ORDER',
  ITEM = 'ITEM',
}

/**
 * Describes a coupon
 */
export class CouponObject {
  /** Type of coupon */
  type: CouponType;

  /** Start of coupon validity */
  validFrom: string;

  /** End of coupon validity */
  validUntil: string;

  /** Product code of coupon */
  code: string;

  /** Rate to apply for discount (per cent) */
  discRate: number;

  /** Required quantity of items with reqTags */
  reqQty?: number[];

  /** Required tags among order products for coupon to be valid */
  reqTag?: string[];

  /** Tag to target discounted product */
  discTag?: string;

  /** Product code of target discounted product */
  discProd?: number[];
}
