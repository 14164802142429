import { Type } from 'class-transformer';
import { IsOptional } from 'class-validator';

/**
 * Describe a restaurant of a campany.
 */
export class EndingStatusObject {
  /**
   * Status code
   */
  @Type(() => Number)
  @IsOptional()
  StatusCode: number;

  /**
   * Status message
   */
  @Type(() => String)
  @IsOptional()
  StatusMessage: string;
}
