import { IsEmail } from 'class-validator';

export class CreateResetPasswordTokenRequest {
  /**
   * Email of the user.
   *
   * @type {string}
   * @memberof CreateResetPasswordTokenRequest
   */
  @IsEmail()
  email: string;
}
