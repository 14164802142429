export enum TaxRuleSaleType {
  EAT_IN = 'EAT_IN',
  TAKE_OUT = 'TAKE_OUT',
}

export class TaxRuleObject {
  id: number;

  campaignId: number;

  taxId: number;

  taxCategoryId: number;

  saleType?: TaxRuleSaleType;

  priority: number;

  breakLowerAmount?: number;

  breakUpperAmount?: number;

  validFromDate: Date;

  validThruDate: Date;
}
