import { Type } from 'class-transformer';
import { IsNumber, ValidateNested } from 'class-validator';

import { OrderItemBaseObject } from '../objects/order-item-base.object';

export class GetOrderConflictsRequest {
  /**
   * Id of the Restaurant.
   *
   * @type {number}
   * @memberof GetOrderConflictsRequest
   */
  @Type(() => Number)
  @IsNumber()
  restaurantId: number;

  /**
   * Id of the Campaign related to the items (!== current campaign id).
   *
   * @type {number}
   * @memberof GetOrderConflictsRequest
   */
  @Type(() => Number)
  @IsNumber()
  campaignId: number;

  /**
   * Base items in the order to validate. Could be nested.
   *
   * @type {OrderItemObject[]}
   * @memberof GetOrderConflictsRequest
   */
  @Type(() => OrderItemBaseObject)
  @ValidateNested()
  items: OrderItemBaseObject[];
}
