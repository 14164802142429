import { Type } from 'class-transformer';

export enum AccountType {
  GUEST = 'GUEST',
  USER = 'USER',
}

/**
 * Describe an account.
 */
export class AccountObject {
  /**
   * Id of the account.
   *
   * @type {number}
   * @memberof AccountObject
   */
  @Type(() => Number)
  id: number;

  /**
   * Type of account.
   *
   * @type {AccountType}
   * @memberof AccountObject
   */
  type: AccountType;

  /**
   * Whether a user / guest accepted the data protection poilicy.
   *
   * @type {boolean}
   * @memberof AccountObject
   */
  isPolicyAccepted: boolean;

  /**
   * Whether a user / guest accepted to receive offers and mails.
   *
   * @type {boolean}
   * @memberof AccountObject
   */
  isOffersAccepted: boolean;
}
