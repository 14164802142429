import { Type } from 'class-transformer';
import { IsString, IsOptional } from 'class-validator';

import { UserGeolocationObject } from './user-geolocation.request';

export class UpdatePredictRequest {
  /**
   * The session id
   *
   * @type {string}
   * @memberof UpdatePredictRequest
   */
  @IsString()
  @IsOptional()
  SessionId: string;

  /**
   * location of the restaurant
   * @memberof UpdatePredictRequest
   */
  @Type(() => UserGeolocationObject)
  @IsOptional()
  Location: UserGeolocationObject;
}
