/**
 * Describes Settings attached to a Restaurant.
 */
export class RestaurantSettingsObject {
  /**
   * Whether Restaurant can take orders
   */
  ordersEnabled?: boolean;

  /**
   * Delay before orders are injected, in minutes
   */
  ordersInjectionDelay?: number;

  /**
   * Merchant ID for Nepting
   */
  merchantId?: string;

  /**
   * Break of injection until this value
   */
  ordersEnabledAt?: Date;

  /**
   * Sign key for Nepting
   */
  signKey?: string;

  /**
   * Restaurant's email
   */
  restaurantEmail?: string;

  /**
   * phone number of the restaurant
   */
  phoneNumber?: string;

  /**
   * Outage URL
   */
  outageURL?: string;
  /**
   * Predict store code
   */
  predictStoreCode?: string;
}
