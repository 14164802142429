import { OrderItemObject } from './order-item.object';

/**
 * Possible types of conflict.
 */
export enum ConflictType {
  PRICE = 'PRICE',
  PARTS = 'PARTS',
  AVAILABILITY = 'AVAILABILITY',
}

/**
 * Represents a conflicted item within an order.
 * Item has changed with latest campaign and can no longer be sold in its current state.
 */
export interface ConflictedOrderItem {
  /**
   * The full, conflicted OrderItemObject.
   *
   * @type {OrderItemObject}
   * @memberof ConflictedOrderItem
   */
  item: OrderItemObject;

  /**
   * The type of conflict found.
   *
   * @type {ConflictType}
   * @memberof ConflictedOrderItem
   */
  conflictType: ConflictType;

  /**
   * The potential parents of the conflicted OrderItemObject.
   *
   * @type {string[]}
   * @memberof ConflictedOrderItem
   */
  parents: string[];
}

/**
 * Represents a subtree of conflicted items within an order.
 * This holds all conflicted items found within a subtree (root and all its children).
 */
export interface ConflictedOrderCategory {
  /**
   * The root of the subtree that contains conflicts.
   *
   * @type {OrderItemObject}
   * @memberof ConflictedOrderCategory
   */
  root: OrderItemObject;

  /**
   * If applicable, the children (level > 0 in subtree) where conflicts have been found.
   *
   * @type {OrderItemObject[]}
   * @memberof ConflictedOrderCategory
   */
  children: OrderItemObject[];
}

/**
 * Represents a category of conflicted items found within an order.
 */
export class ConflictedOrderCategoriesObject {
  /**
   * Holds all subtrees of order where some items are no longer available.
   *
   * @type {ConflictedOrderCategory[]}
   * @memberof ConflictedOrderCategoriesObject
   */
  disabledItems: ConflictedOrderCategory[];

  /**
   * Holds all subtrees of order where some items have changed price.
   *
   * @type {ConflictedOrderCategory[]}
   * @memberof ConflictedOrderCategoriesObject
   */
  priceChangedItems: ConflictedOrderCategory[];

  /**
   * Holds all subtrees of order where part requirements of some items have changed.
   *
   * @type {ConflictedOrderCategory[]}
   * @memberof ConflictedOrderCategoriesObject
   */
  partsChangedItems: ConflictedOrderCategory[];
}
