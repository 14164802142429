import { IsNumber, IsPositive } from 'class-validator';

export class CreateFakePaymentRequest {
  /**
   * Order related to this payment.
   *
   * @type {number}
   * @memberof CreateFakePaymentRequest
   */
  @IsNumber()
  @IsPositive()
  orderId: number;
}
