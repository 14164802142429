import { IsBoolean, IsOptional, IsString } from 'class-validator';

export class SendFeedbackRequest {
  /**
   * Email of the user.
   *
   * @type {string}
   * @memberof SendFeedbackRequest
   */
  @IsString()
  @IsOptional()
  phoneNumber?: string;

  /**
   * Subject of the email.
   *
   * @type {string}
   * @memberof SendFeedbackRequest
   */
  @IsString()
  @IsOptional()
  feedbackSubject?: string;

  /**
   * Body of the email.
   *
   * @type {string}
   * @memberof SendFeedbackRequest
   */
  @IsString()
  body: string;

  /**
   * Name of the user.
   *
   * @type {string}
   * @memberof SendFeedbackRequest
   */
  @IsString()
  @IsOptional()
  name?: string;

  /**
   * Email of the user.
   *
   * @type {string}
   * @memberof SendFeedbackRequest
   */
  @IsString()
  @IsOptional()
  email?: string;

  /**
   * Body of the email.
   *
   * @type {boolean}
   * @memberof SendFeedbackRequest
   */
  @IsBoolean()
  @IsOptional()
  reportProblem?: boolean;
}
