import { IsNotEmpty, IsOptional, IsString, Length } from 'class-validator';

export class UpdateUserRequest {
  /**
   * First name of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  firstName?: string;

  /**
   * Last name of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  lastName?: string;

  /**
   * Address of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  addressLine1?: string;

  /**
   * Address of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsOptional()
  addressLine2?: string;

  /**
   * Complementary data address of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsOptional()
  addressComplementary?: string;

  /**
   * Postal code of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  postalCode?: string;

  /**
   * City of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  city?: string;

  /**
   * Language of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  language?: string;

  /**
   * Password of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  @Length(6)
  @IsOptional()
  password?: string;

  /**
   * Phone number of the user.
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  @IsString()
  @IsOptional()
  phoneNumber?: string;
}
