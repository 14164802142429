import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsLatitude,
  IsLongitude,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  Min,
  ValidateIf,
} from 'class-validator';

export class FindRestaurantsRequest {
  /**
   * number of elements displayed
   * @default 20
   */
  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsOptional()
  limit = 20;

  /**
   * number of elements skipped
   * @default 0
   */
  @Type(() => Number)
  @IsNumber()
  @Min(0)
  @IsOptional()
  skip = 0;

  /**
   * custom search
   */
  @Type(() => Number)
  @IsString()
  @IsOptional()
  search?: string;

  /**
   * sort by index, price && ascending or descending etc.
   */
  @Type(() => Number)
  @IsString()
  @IsOptional()
  sortBy?: string;

  /**
   * optional posStoreId to filter against
   */
  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsOptional()
  posStoreId?: number;

  /**
   * optional latitude to filter against (mandatory if radius or lng present)
   */
  @ValidateIf((request) => request.lng !== undefined || request.radius !== undefined)
  @Type(() => Number)
  @IsLatitude()
  lat?: number;

  /**
   * optional longitude to filter against (mandatory if lat or radius present)
   */
  @ValidateIf((request) => request.lat !== undefined || request.radius !== undefined)
  @Type(() => Number)
  @IsLongitude()
  lng?: number;

  /**
   * optional radius, in Km
   */
  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsOptional()
  radius?: number;

  /**
   * id of the logged user
   */
  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsOptional()
  userId?: number;

  /**
   * boolean to determine if we fetch only favorite restaurants
   */
  @Type(() => Boolean)
  @IsBoolean()
  @IsOptional()
  favoritesOnly?: boolean;
}
