import { AuthenticationTokensObject, UserObject } from '@acrelec-cloud/apico-shared';
import { Type } from 'class-transformer';

export class UseRefreshTokenResponse {
  success: boolean;

  error?: string;

  @Type(() => UserObject)
  user?: UserObject;

  @Type(() => AuthenticationTokensObject)
  authenticationTokens?: AuthenticationTokensObject;
}
