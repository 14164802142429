import {
  Equals,
  IsBoolean,
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
  Length,
} from 'class-validator';

export class ValidateUserRequest {
  /**
   * Validation token provided upon creation.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  validationToken: string;

  /**
   * Email of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsEmail()
  email: string;

  /**
   * First name of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  firstName: string;

  /**
   * Last name of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  lastName: string;

  /**
   * Address of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  addressLine1: string;

  /**
   * Address of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsOptional()
  addressLine2?: string;

  /**
   * Complementary data address of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsOptional()
  addressComplementary?: string;

  /**
   * Postal code of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  postalCode: string;

  /**
   * City of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  city: string;

  /**
   * Password of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  @IsNotEmpty()
  @Length(6)
  password: string;

  /**
   * Language of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsNotEmpty()
  language: string;

  /**
   * Whether user has accepted terms of use.
   *
   * @type {boolean}
   * @memberof ValidateUserRequest
   */
  @IsBoolean()
  @Equals(true)
  isTermsAccepted: boolean;

  /**
   * Whether user has accepted the data protection policy
   *
   * @type {boolean}
   * @memberof CreateGuestRequest
   */
  @IsBoolean()
  isPolicyAccepted: boolean;

  /**
   *  Whether user has accepted to receive offers and news
   *
   * @type {boolean}
   * @memberof CreateGuestRequest
   */
  @IsBoolean()
  isOffersAccepted: boolean;

  /**
   * Phone number of the user.
   *
   * @type {string}
   * @memberof ValidateUserRequest
   */
  @IsString()
  @IsOptional()
  phoneNumber?: string;
}
