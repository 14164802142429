import { IsEmail, IsNotEmpty, IsString, Length } from 'class-validator';

export class ResetPasswordRequest {
  /**
   * Reset token generated on reset request.
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  @IsString()
  @IsNotEmpty()
  resetPasswordToken: string;

  /**
   * Email of the user.
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  @IsEmail()
  email: string;

  /**
   * Password of the user.
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  @IsString()
  @IsNotEmpty()
  @Length(6)
  password: string;
}
