export enum OutageType {
  DISABLED = 'DISABLED',
  UNAVAILABLE = 'UNAVAILABLE',
  AVAILABLE = 'AVAILABLE',
}

export class RestaurantOutageProductsObject {
  /**
   * productCode of outage
   */
  productCode: number;

  /**
   * state of Outage (UNAVAILABLE / DISABLED)
   */
  state: OutageType;
}
