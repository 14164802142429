import { Type } from 'class-transformer';
import { IsOptional, IsString } from 'class-validator';

import { OrdersObject } from './orders.request';

export class AdditionnalDetailsRequest {
  /**
   * Id of the user
   *
   * @type {string}
   * @memberof AdditionnalDetailsRequest
   */
  @IsString()
  @IsOptional()
  CustomerIdentifier: string;

  /**
   * Name of the user
   *
   * @type {string}
   * @memberof AdditionnalDetailsRequest
   */
  @IsString()
  @IsOptional()
  CustomerName: string;

  /**
   * Array of orders id's
   *
   * @type {string}
   * @memberof AdditionnalDetailsRequest
   */
  @IsOptional()
  @Type(() => OrdersObject)
  Orders: OrdersObject[];

  /**
   * Additionnal details
   *
   * @type {string}
   * @memberof AdditionnalDetailsRequest
   */
  @IsString()
  @IsOptional()
  Details: string;

  /**
   * Parking bay number
   *
   * @type {string}
   * @memberof AdditionnalDetailsRequest
   */
  @IsString()
  @IsOptional()
  ParkingBayNumber: string;
}
