import { IsBoolean, IsArray } from 'class-validator';

export class UpdateRestaurantsDataRequest {
  /**
   * Update (un)available for outage
   *
   * @type {boolean}
   * @memberof UpdateRestaurantsDataRequest
   */
  @IsBoolean()
  new_outage: boolean;

  /**
   * Update (un)available for menu
   *
   * @type {boolean}
   * @memberof UpdateRestaurantsDataRequest
   */
  @IsBoolean()
  new_menu: boolean;

  /**
   * When outage is updated (= true)
   *
   * @type {boolean}
   * @memberof UpdateRestaurantsDataRequest
   */
  @IsArray()
  store_id: [];
}
