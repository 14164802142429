import { Type } from 'class-transformer';
import { IsArray, IsNumber, IsOptional, ValidateNested } from 'class-validator';

/**
 * Represent an item inside an order.
 */
export class OrderItemBaseObject {
  /**
   * Code of the product.
   *
   * @type {number}
   * @memberof OrderItemBaseObject
   */
  @IsNumber()
  productCode: number;

  /**
   * Price context of the product
   *
   * @type {number}
   * @memberof OrderItemObject
   */
  @IsNumber()
  @IsOptional()
  contextCode: number;

  /**
   * Quantity of the item.
   *
   * @type {number}
   * @memberof OrderItemBaseObject
   */
  @IsNumber()
  quantity: number;

  /**
   * Children of the product. Could be modifiers or combo options.
   *
   * @type {OrderItemBaseObject[]}
   * @memberof OrderItemBaseObject
   */
  @Type(() => OrderItemBaseObject)
  @ValidateNested()
  @IsArray()
  children: OrderItemBaseObject[];
}
