import { Type } from 'class-transformer';
import { IsNumber, IsOptional, IsPositive, Min } from 'class-validator';

export class FindOrdersRequest {
  /**
   * accountId to filter against, mandatory for now,
   * will be optional for admin accounts
   */
  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  accountId: number;
  /**
   * orderState define if we fetch only pending orders or all order history
   * The states would be PENDING or ORDER_HISTORY
   * Pending orders > orders on time to inject > current time + orders injection delay
   */
  @Type(() => String)
  @IsOptional()
  orderState: string;

  /**
   * number of elements skipped
   * @default 0
   */
  @Type(() => Number)
  @IsNumber()
  @Min(0)
  @IsOptional()
  skip = 0;

  /**
   * number of elements displayed
   * @default 20
   */
  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsOptional()
  limit = 20;

  /**
   * the states param is optional, it will filter and
   * return only orders with the resquested states.
   * Separate the order states (OrderState enum) by comma.
   * DON'T USE SPACES!
   * Example: CREATED,VALIDATED
   */
  @Type(() => String)
  @IsOptional()
  validStates?: string;

  /**
   * checkUpdate is an optional date parameters
   * to check all orders and get only the news/recents
   * orders updated
   */
  @Type(() => String)
  @IsOptional()
  checkUpdate?: string;

  /**
   * Optionnal argument to know if we want to only fetch orders
   * from mobile or web
   */
  @Type(() => Boolean)
  @IsOptional()
  mobileOnly?: boolean;
}
