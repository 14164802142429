import {
  CreateResetPasswordTokenRequest,
  CreateUserRequest,
  ResetPasswordRequest,
  UpdateUserRequest,
  UserFavoriteRestaurantObject,
  UserObject,
  ValidateUserRequest,
} from '@acrelec-cloud/apico-shared';
import axios, { AxiosInstance } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { ApicoSDK } from '../../apico-sdk';

export interface UserDeleteResult {
  success: boolean;
}

export class UsersService {
  protected readonly axiosInstance: AxiosInstance;

  constructor(protected readonly sdk: ApicoSDK) {
    this.axiosInstance = axios.create({
      baseURL: sdk.getEndpoint(),
      withCredentials: true,
    });
    axiosBetterStacktrace(this.axiosInstance);
  }

  async createUser(createUserRequest: CreateUserRequest): Promise<void> {
    return this.axiosInstance
      .post(`/accounts/users`, createUserRequest)
      .then(response => response.data);
  }

  async validateUser(
    validateUserRequest: ValidateUserRequest,
    captchaToken: string,
  ): Promise<UserObject> {
    return this.axiosInstance
      .post(
        `/accounts/users/validate`,
        {
          ...validateUserRequest,
        },
        {
          headers: {
            recaptcha: captchaToken,
          },
        },
      )
      .then(response => response.data);
  }

  async updateUser(userId: number, updateUserRequest: UpdateUserRequest): Promise<UserObject> {
    return this.axiosInstance
      .patch(`/accounts/users/${userId}`, updateUserRequest)
      .then(response => response.data);
  }

  async getUser(userId: number): Promise<UserObject> {
    return this.axiosInstance.get(`/accounts/users/${userId}`).then(response => response.data);
  }

  async generateResetToken(
    createResetPasswordTokenRequest: CreateResetPasswordTokenRequest,
  ): Promise<void> {
    return this.axiosInstance
      .post(`/accounts/users/lost-password`, createResetPasswordTokenRequest)
      .then(response => response.data);
  }

  async resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<void> {
    return this.axiosInstance
      .post(`/accounts/users/reset-password`, resetPasswordRequest)
      .then(response => response.data);
  }

  async deleteUser(userId: number): Promise<UserDeleteResult> {
    return this.axiosInstance.delete(`/accounts/users/${userId}`).then(response => response.data);
  }

  async addFavoriteRestaurant(restaurantId: number): Promise<UserFavoriteRestaurantObject> {
    return this.axiosInstance
      .post(`/accounts/users/${restaurantId}`)
      .then(response => response.data);
  }

  async removeFavoriteRestaurant(restaurantId: number): Promise<void> {
    return this.axiosInstance
      .delete(`/accounts/users/delete/${restaurantId}`)
      .then(response => response.data);
  }
}
