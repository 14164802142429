import {
  AuthenticatedAccountObject,
  AuthenticateGuestRequest,
  AuthenticateUserRequest,
  AuthenticateExternalUserRequest,
} from '@acrelec-cloud/apico-shared';
import axios, { AxiosInstance } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { ApicoSDK } from '../../apico-sdk';
import { AuthenticateGuestResponse } from './responses/authenticate-guest.response';
import { AuthenticateUserResponse } from './responses/authenticate-user.response';
import { UseRefreshTokenResponse } from './responses/use-refresh-token.response';

export class AuthenticationService {
  protected readonly axiosInstance: AxiosInstance;

  constructor(protected readonly sdk: ApicoSDK) {
    this.axiosInstance = axios.create({
      baseURL: sdk.getEndpoint(),
      withCredentials: true,
    });
    axiosBetterStacktrace(this.axiosInstance);
  }

  async authenticateGuest(
    authenticateGuestData: AuthenticateGuestRequest,
  ): Promise<AuthenticateGuestResponse> {
    return this.axiosInstance
      .post(`/accounts/authentication/guests`, authenticateGuestData)
      .then(response => response.data);
  }
  async authenticateUser(
    authenticateUserData: AuthenticateUserRequest,
  ): Promise<AuthenticateUserResponse> {
    return this.axiosInstance
      .post(`/accounts/authentication/users`, authenticateUserData)
      .then(response => response.data);
  }
  async authenticateExternalUser(
    authenticateExternalUserData: AuthenticateExternalUserRequest,
  ): Promise<AuthenticateUserResponse> {
    return this.axiosInstance
      .post(`/accounts/authentication/users-sso`, authenticateExternalUserData)
      .then(response => {
        return response.data;
      });
  }

  async logout(): Promise<void> {
    return this.axiosInstance
      .post(`/accounts/authentication/logout`)
      .then(response => response.data);
  }

  async currentAuthenticated(): Promise<AuthenticatedAccountObject> {
    return this.axiosInstance
      .get(`/accounts/authentication/current`)
      .then(response => response.data);
  }

  async useRefreshToken(): Promise<UseRefreshTokenResponse> {
    return this.axiosInstance
      .get(`/accounts/authentication/refresh-token`)
      .then(response => response.data);
  }
}
