import { Type } from 'class-transformer';
import { IsDate, IsNumber, IsOptional, IsPositive, Min } from 'class-validator';

export class RestaurantOrdersRequest {
  /**
   * number of elements displayed
   * @default 20
   */
  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsOptional()
  limit = 999999999;

  /**
   * number of elements skipped
   * @default 0
   */
  @Type(() => Number)
  @IsNumber()
  @Min(0)
  @IsOptional()
  skip = 0;

  /**
   * filter by date, STARTING at
   * @default current date
   */
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  startDate: Date;

  /**
   * filter by date, ENDING at
   * @default current date
   */
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  endDate: Date;
}
