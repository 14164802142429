import { Type } from 'class-transformer';

import { ProductObject } from './product.object';

/**
 * @deprecated
 */
export enum ProductPartType {
  UNIQUE = 'UNIQUE',
  SELECT = 'SELECT',
}

/**
 * Describe a part of a product.
 * A part can be an option or a modifier.
 */
export class ProductPartObject {
  /**
   * @deprecated Unused, will be removed in future versions
   * @type {ProductPartType}
   * @memberof ProductPartObject
   */
  type: ProductPartType;
  /** Number of free products in the part. */
  includedQuantity: number;
  /** Default number of products in the part. */
  minQuantity: number;
  /** Minimum number of products in the part. */
  defaultQuantity: number;
  /** Maximum number of products in the part. */
  maxQuantity: number;
  /** Id of the parent product. */
  productCode: number;
  /** Id of the product part. */
  partCode: number;

  /**
   * Product related to the product code.
   *
   * @deprecated Currently filled by the server, will be deleted after the MVP
   * @type {ProductObject}
   * @memberof ProductPartObject
   */
  @Type(() => ProductObject)
  product?: ProductObject;
}
