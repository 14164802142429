import { IsNumber, IsPositive } from 'class-validator';

export class UserFavoriteRestaurantObject {
  /**
   * Id of relation .
   *
   * @type {number}
   * @memberof UserFavoriteRestaurantObject
   */
  @IsNumber()
  @IsPositive()
  id: number;

  /**
   * Id of the restaurant .
   *
   * @type {number}
   * @memberof UserFavoriteRestaurantObject
   */
  @IsNumber()
  @IsPositive()
  restaurantId: number;

  /**
   * Id of the user
   *
   * @type {number}
   * @memberof UserFavoriteRestaurantObject
   */
  @IsNumber()
  @IsPositive()
  userId: number;
}
