import { Type } from 'class-transformer';
import { IsString, IsOptional } from 'class-validator';

import { AdditionnalDetailsRequest } from './additionnal-details.request';
import { UserGeolocationObject } from './user-geolocation.request';

export class StartPredictRequest {
  /**
   * The store code defined by Predict
   *
   * @type {string}
   * @memberof StartPredictRequest
   */
  @IsString()
  @IsOptional()
  StoreCode: string;

  /**
   * location of the restaurant
   * @memberof StartPredictRequest
   */
  @IsOptional()
  @Type(() => UserGeolocationObject)
  Location: UserGeolocationObject;

  /**
   * Details of the user
   *
   * @memberof StartPredictRequest
   */
  @IsOptional()
  @Type(() => AdditionnalDetailsRequest)
  AdditionalDetails: AdditionnalDetailsRequest;
}
