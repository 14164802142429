import { IsObject, IsString } from 'class-validator';

export class StripeWebhookRequest {
  @IsString()
  event: string;

  @IsObject()
  data: {
    object: string;
  };
}
