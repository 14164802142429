import { IsOptional } from 'class-validator';

/**
 * Describe location of a user.
 */
export class UserGeolocationObject {
  /** Longitude of the user */
  @IsOptional()
  Long: number;
  /** Latitude of the user */
  @IsOptional()
  Lat: number;
}
