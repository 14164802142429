/**
 * Describe location of a restaurant.
 */
export class RestaurantGeolocationObject {
  /** Latitude of the restaurant */
  latitude: number;

  /** Longitude of the restaurant */
  longitude: number;
}
