import {
  FindRestaurantsRequest,
  PaginatedResponse,
  RestaurantObject,
} from '@acrelec-cloud/apico-shared';
import axios, { AxiosInstance } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { ApicoSDK } from '../../apico-sdk';

/**
 * Service used to request the restaurants api.
 */
export class RestaurantsService {
  protected readonly axiosInstance: AxiosInstance;

  constructor(private readonly sdk: ApicoSDK) {
    this.axiosInstance = axios.create({
      baseURL: sdk.getEndpoint(),
    });
    axiosBetterStacktrace(this.axiosInstance);
  }

  /**
   * Find restaurants.
   *
   * @param paginationOptions pagination
   * @returns restaurant list
   */
  async findRestaurants(
    paginationOptions: FindRestaurantsRequest,
  ): Promise<PaginatedResponse<RestaurantObject>> {
    return this.axiosInstance
      .get(`/restaurants`, {
        params: paginationOptions,
      })
      .then(response => response.data);
  }

  /**
   * Get restaurant by id or slug name
   *
   * @param slugOrId
   * @returns restaurant details
   */
  async getRestaurant(slugOrId: string | number): Promise<RestaurantObject> {
    return this.axiosInstance.get(`/restaurants/${slugOrId}`).then(response => response.data);
  }
}
