import { AuthenticationTokensObject, GuestObject } from '@acrelec-cloud/apico-shared';
import { Type } from 'class-transformer';

export class AuthenticateGuestResponse {
  @Type(() => GuestObject)
  guest: GuestObject;

  @Type(() => AuthenticationTokensObject)
  authenticationTokens: AuthenticationTokensObject;
}
