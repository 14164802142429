/**
 * Describe dimensions of a product.
 */
export class ProductDimensionObject {
  /**
   * Product Code
   *
   * @type {number}
   * @memberof ProductDimensionObject
   */
  productCode: number;

  /**
   * Dimension Label
   *
   * @type {string}
   * @memberof ProductDimensionObject
   */
  dimensionChar: string;

  /**
   * Dimension Priority
   *
   * @type {number}
   * @memberof ProductDimensionObject
   */
  dimensionPriority: number;
}
