export interface TranslationItem {
  /**
   * Language of translation (ex: 'FR', 'EN')
   */
  language: string; //FR, EN

  /**
   * Translation text
   */
  value: string;
}

/**
 * Describe translations of a product.
 */
export class ProductTranslationObject {
  /**
   * Translation code (ex: 'name', 'description')
   */
  code: string;

  /**
   * Translation's list
   */
  items: TranslationItem[];
}
