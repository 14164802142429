import { CreateGuestRequest, GuestObject } from '@acrelec-cloud/apico-shared';
import axios, { AxiosInstance } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { ApicoSDK } from '../../apico-sdk';

export class GuestsService {
  protected readonly axiosInstance: AxiosInstance;

  constructor(protected readonly sdk: ApicoSDK) {
    this.axiosInstance = axios.create({
      baseURL: sdk.getEndpoint(),
      withCredentials: true,
    });
    axiosBetterStacktrace(this.axiosInstance);
  }

  async createGuest(createGuestRequest: CreateGuestRequest): Promise<GuestObject> {
    return this.axiosInstance
      .post(`/accounts/guests`, createGuestRequest)
      .then(response => response.data);
  }

  async getGuest(guestId: number): Promise<GuestObject> {
    return this.axiosInstance.get(`/accounts/guests/${guestId}`).then(response => response.data);
  }
}
