import { AuthenticationTokensObject, UserObject } from '@acrelec-cloud/apico-shared';
import { Type } from 'class-transformer';

export class AuthenticateUserResponse {
  @Type(() => UserObject)
  user: UserObject;

  @Type(() => AuthenticationTokensObject)
  authenticationTokens: AuthenticationTokensObject;
}
