import {
  NavigationPageObject,
  NavigationProductObject,
  ProductClassificationObject,
  ProductObject,
  ProductPartObject,
  ProductPriceObject,
  BrandBannerObject,
  GetProductsByFilterObject,
} from '@acrelec-cloud/apico-shared';
import axios, { AxiosInstance } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { ApicoSDK } from '../../apico-sdk';

export interface ContentsRequestOptions {
  // Id of the restaurant
  restaurantId?: number;
  // Id of the campaign
  campaignId?: number;
}

/**
 * Encapsulate all the requests related to the contents.
 * Depends on a restaurantId and a campaignId.
 * The campaignId is unpredictable and is provided by the restaurants service.
 * @see {RestaurantObject}
 */
export class ContentsService {
  protected readonly axiosInstance: AxiosInstance;

  constructor(private readonly sdk: ApicoSDK) {
    this.axiosInstance = axios.create({
      baseURL: sdk.getEndpoint(),
    });
    axiosBetterStacktrace(this.axiosInstance);
  }

  /**
   * To get information of product with productCode.
   *
   * @param productId Id of the product.
   * @param options Request options.
   * @returns {Product} Product details.
   */
  async getProduct(
    productCode: number,
    contextCode?: number,
    options: ContentsRequestOptions = {},
  ): Promise<ProductObject> {
    const restaurantId = options.restaurantId || this.sdk.getDefaults()?.restaurantId;
    const campaignId = options.campaignId || this.sdk.getDefaults()?.campaignId;
    return this.axiosInstance
      .get(`/restaurants/${restaurantId}/campaigns/${campaignId}/products/${productCode}`, {
        params: {
          contextCode,
        },
      })
      .then(response => response.data);
  }

  /**
   * Get products by filter
   *
   * @param restaurantId ID of currentRestaurant
   * @param campaignId campaignId of currentRestaurant
   * @param filters Object dedicated to filters (name, navigation..)
   */
  async getProductsByFilter(data: GetProductsByFilterObject): Promise<ProductObject[]> {
    const {
      restaurantId = this.sdk.getDefaults()?.restaurantId,
      campaignId = this.sdk.getDefaults()?.campaignId,
      filters = {},
    } = data;
    return this.axiosInstance
      .get(`/restaurants/${restaurantId}/campaigns/${campaignId}/products`, {
        params: {
          filterByName: filters.name,
        },
      })
      .then(response => response.data);
  }

  /**
   * TO get the price information of a product in a certain context.
   *
   * @param productCode Product code.
   * @param contextCode Context code for the price.
   * @param options Request options.
   * @returns Product price or throw an error if does not exist.
   */
  async getProductPrice(
    productCode: number,
    contextCode?: number,
    options: ContentsRequestOptions = {},
  ): Promise<ProductPriceObject> {
    const restaurantId = options.restaurantId || this.sdk.getDefaults()?.restaurantId;
    const campaignId = options.campaignId || this.sdk.getDefaults()?.campaignId;
    return this.axiosInstance
      .get(
        `/restaurants/${restaurantId}/campaigns/${campaignId}/products/${productCode}/prices/${contextCode}`,
      )
      .then(response => response.data);
  }

  /**
   * Get products parts of a product.
   *
   * @param productCode Code of Product.
   * @param options Request options.
   */
  async getProductParts(
    productCode: number,
    options: ContentsRequestOptions = {},
  ): Promise<ProductPartObject[]> {
    const restaurantId = options.restaurantId || this.sdk.getDefaults()?.restaurantId;
    const campaignId = options.campaignId || this.sdk.getDefaults()?.campaignId;
    return this.axiosInstance
      .get(`/restaurants/${restaurantId}/campaigns/${campaignId}/products/${productCode}/parts`)
      .then(response => response.data);
  }

  /**
   * Get products options of a product.
   *
   * @param productCode Code of Product.
   * @param options Request options.
   */
  async getProductOptions(
    productCode: number,
    options: ContentsRequestOptions = {},
  ): Promise<ProductClassificationObject[]> {
    const restaurantId = options.restaurantId || this.sdk.getDefaults()?.restaurantId;
    const campaignId = options.campaignId || this.sdk.getDefaults()?.campaignId;
    return this.axiosInstance
      .get(`/restaurants/${restaurantId}/campaigns/${campaignId}/products/${productCode}/options`)
      .then(response => response.data);
  }

  /**
   * Get a root navigation detail by its name.
   *
   * @param navigationName Name of the root navigation.
   * @param options Request options.
   */
  async getRootNavigation(
    navigationName: string,
    options: ContentsRequestOptions = {},
  ): Promise<NavigationPageObject> {
    const restaurantId = options.restaurantId || this.sdk.getDefaults()?.restaurantId;
    const campaignId = options.campaignId || this.sdk.getDefaults()?.campaignId;
    return this.axiosInstance
      .get(`/restaurants/${restaurantId}/campaigns/${campaignId}/navigations`, {
        params: {
          navigationName,
        },
      })
      .then(response => response.data);
  }

  /**
   * Get outage products
   *
   * @param options Request options.
   * @param productCode Get outage of specific product.
   */
  async getProductOutage(options: ContentsRequestOptions = {}, productCode?: number): Promise<any> {
    const restaurantId = options.restaurantId || this.sdk.getDefaults()?.restaurantId;
    const campaignId = options.campaignId || this.sdk.getDefaults()?.campaignId;
    return this.axiosInstance
      .get(
        `/restaurants/${restaurantId}/campaigns/${campaignId}/outages${
          productCode ? `/${productCode}` : ''
        }`,
      )
      .then(response => response.data);
  }

  /**
   * Get navigation detail.
   *
   * @param navigationId Id of the navigation.
   * @param options Request options.
   */
  async getNavigation(
    navigationId: number,
    options: ContentsRequestOptions = {},
  ): Promise<NavigationPageObject> {
    const restaurantId = options.restaurantId || this.sdk.getDefaults()?.restaurantId;
    const campaignId = options.campaignId || this.sdk.getDefaults()?.campaignId;
    return this.axiosInstance
      .get(`/restaurants/${restaurantId}/campaigns/${campaignId}/navigations/${navigationId}`)
      .then(response => response.data);
  }

  /**
   * Get navigation children.
   *
   * @param navigationId Id of the navigation.
   * @param options Request options.
   */
  async getNavigationChildren(
    navigationId: number,
    options: ContentsRequestOptions = {},
  ): Promise<NavigationPageObject[]> {
    const restaurantId = options.restaurantId || this.sdk.getDefaults()?.restaurantId;
    const campaignId = options.campaignId || this.sdk.getDefaults()?.campaignId;
    return this.axiosInstance
      .get(
        `/restaurants/${restaurantId}/campaigns/${campaignId}/navigations/${navigationId}/children`,
      )
      .then(response => response.data);
  }

  /**
   * Get products inside a navigation.
   *
   * @param navigationId Id of the navigation.
   * @param options Request options.
   */
  async getNavigationProducts(
    navigationId: number,
    options: ContentsRequestOptions = {},
  ): Promise<NavigationProductObject[]> {
    const restaurantId = options.restaurantId || this.sdk.getDefaults()?.restaurantId;
    const campaignId = options.campaignId || this.sdk.getDefaults()?.campaignId;
    return this.axiosInstance
      .get(
        `/restaurants/${restaurantId}/campaigns/${campaignId}/navigations/${navigationId}/products`,
      )
      .then(response => response.data);
  }

  /**
   * To get brand banners
   */
  async getBrandBanners(): Promise<BrandBannerObject[]> {
    return this.axiosInstance.get(`/brand/banners/`).then(response => response.data);
  }
}
