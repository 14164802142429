/**
 * Describe opening of a restaurant in a day.
 * Most of the time this object will be in an array of days.
 */
export class RestaurantOpeningHoursObject {
  /** Name of the day */
  name: string;

  /**
   * Opening time of the restaurant
   * must be written like this : 11:00|01:59
   */
  value: string;
}
