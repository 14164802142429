import { IsString } from 'class-validator';

/**
 * Informations about the customer related to an order.
 */
export class CustomerDetailsObject {
  /**
   * Firstname of the customer.
   *
   * @type {string}
   * @memberof OrderItemBaseObject
   */
  @IsString()
  firstName: string;

  /**
   * Lastname of the customer.
   *
   * @type {string}
   * @memberof OrderItemBaseObject
   */
  @IsString()
  lastName: string;

  /**
   * Email of the customer.
   *
   * @type {string}
   * @memberof OrderItemBaseObject
   */
  @IsString()
  email: string;

  /**
   * Phone number of the customer.
   *
   * @type {string}
   * @memberof OrderItemBaseObject
   */
  @IsString()
  phoneNumber: string;
}
