import { Type } from 'class-transformer';

import { ProductObject } from './product.object';

/**
 * Describe an option of a product.
 */
export class ProductClassificationObject {
  campaignId: number;

  productCode: number;
  /** Id of the product parent. */
  classificationCode: number;

  /**
   * Product related to the product code.
   *
   * @deprecated Currently filled by the server, will be deleted after the MVP
   * @type {ProductObject}
   * @memberof ProductPartObject
   */
  @Type(() => ProductObject)
  product?: ProductObject;
}
